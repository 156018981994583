<template>
    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" style="enable-background:new 0 0 1000 1000;" xml:space="preserve">
        <path class="st0" d="M515.5,250v59c58.4,1.1,105.5,48.8,105.5,107.5S573.9,522.9,515.5,524v59c92,0,166.5-74.5,166.5-166.5S607.5,250,515.5,250z"/>
        <path class="st0" d="M425,583c-1.1,58.4-48.8,105.5-107.5,105.5v61c92,0,166.5-74.5,166.5-166.5V250h-60L425,583z"/>
    </svg>
</template>
<script>
export default {
    name: 'Logo',
};
</script>
<style lang="scss" scoped>
    .st0{fill:#0f6163;}
    .st1{fill:none;stroke:#0F4F50;stroke-width:3;stroke-miterlimit:10;}
</style>
