export default [
    {
        text: 'Ain´t no Mountain high enough.',
        author: 'Marvin Gaye',
    },
    {
        text: 'We are the Champions my friend.',
        author: 'Queen',
    },
    {
        text: 'Hello, is it me you`re looking for?',
        author: 'Lionel Richie',
    },
    {
        text: 'What you want baby, I got it.',
        author: 'Aretha Franklin',
    },
];
